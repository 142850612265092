import React from "react";
import "./App.css";

function App() {
  return (
    <div className="App">
      <section className="App-header" id="header">
        <div className="inner">
          <span className="image-center fit">
            <img className="profile-img" src={"../image0.jpeg"} alt="" />
          </span>

          <h1>
            Hi, I'm <strong>Michael</strong>.
          </h1>
          <p>Software Engineer</p>
          <ul className="actions special">
            <li>
              <a href="#one" className="button scrolly">
                My Work
              </a>
            </li>
          </ul>
        </div>
      </section>

      <section id="one" className="main style1">
        <div className="container">
          <div className="row gtr-150">
            <div className="col-12 col-12-medium">
              <header className="major">
                <h2>About Me</h2>
              </header>
              <p>
                I’ve been interested in programming for years, ever since I
                first started learning about MS Basic back in high school. I’ve
                since taken a few classes to hone my skills and taught myself
                how to program in multiple languages. I love to take on new
                personal projects to test my abilities and learn new tricks.
                What I learn, I take to previous and future projects. I pride
                myself on my ability to adapt to new techniques and new
                information.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Two --> */}
      <section id="two" className="main style2">
        <div className="container">
          <div className="row text-center gtr-150">
            <div className="col-12 col-12-medium">
              <header className="major">
                <h2>Things I'm good at</h2>
              </header>
            </div>
            <div className="col-12 col-12-medium">
              <ul className="major-icons">
                <li>
                  <div className="icon">
                    <h3 className="icon-inner">
                      <strong>C#/.NET</strong>
                    </h3>
                  </div>
                </li>
                <li>
                  <div className="icon">
                    <h3 className="icon-inner">
                      <strong>C++</strong>
                    </h3>
                  </div>
                </li>
                <li>
                  <div className="icon">
                    <h3 className="icon-inner">
                      <strong>SQL</strong>
                    </h3>
                  </div>
                </li>
                <li>
                  <div className="icon">
                    <h3 className="icon-inner">
                      <strong>Linq</strong>
                    </h3>
                  </div>
                </li>

                <li>
                  <div className="icon">
                    <h3 className="icon-inner">
                      <strong>Javascript</strong>
                    </h3>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-12 col-12-medium">
              <p>
                I'm really looking forward to learning functional style programming and growing my knowledge in database and server maintenance. 
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Three --> */}
      <section id="three" className="main style1 special">
        <div className="container">
          <header className="major">
            <h2>My Recent Work</h2>
          </header>
          <div className="row gtr-150">
            <div className="col-6 col-12-medium">
              <h3>PokeParadise</h3>
              <p>
                This is a personal project developed in the month of October
                2020. I took it upon myself to create a bot program for the chat
                client Discord, to give my friends and I a game to play. The
                program runs asynchronously, gathering user data from any one of
                dozens of different “channel” sources. The project grew quickly,
                and now has many different functionalities. There have been more
                than a dozen people play-testing this program with all positive
                results.
              </p>
              <ul className="actions special">
                <li>
                  <a
                    href="https://github.com/MichaelKastl/PokeParadise"
                    className="button"
                  >
                    View in Github
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-6 col-12-medium">
              <h3>Check-In GUI</h3>
              <p>
                I was contracted by my previous employers to create a database
                and create a GUI to ingest user input and compare it against the
                database. The end product handles multiple parts of the process
                of retesting and re-certifying HAZMAT cylinders to Department of
                Transportation standards. Repair/maintenance parts for each
                cylinder are automatically added to a spreadsheet to be billed
                to the customer. The project in its entirety saved hundreds of
                hours of work, as well as cutting down on paperwork.
              </p>
              <ul className="actions special">
                <li>
                  <a
                    href="https://github.com/MichaelKastl/CheckIn"
                    className="button"
                  >
                    View in Github
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Four --> */}
      <section id="four" className="main style2 special">
        <div className="container">
          <header className="major">
            <h2>Let's work together</h2>
          </header>
          <ul className="actions special">
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/in/michael-kastl-6b66861b9/"
                className="button wide primary"
              >
                LinkedIn
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://github.com/MichaelKastl"
                className="button wide primary"
              >
                Github
              </a>
            </li>
            <li>
              <a
                href="mailto:michael.kastl96@gmail.com
                "
                className="button wide"
              >
                Contact Me
              </a>
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
}

export default App;
