import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

// firebase.initializeApp({
//   apiKey: "AIzaSyClMjOePXfMcJDKcjAzHREsYSx-rtWsH70",
//   authDomain: "michael-professional-web.firebaseapp.com",
//   databaseURL: "https://michael-professional-web.firebaseio.com",
//   projectId: "michael-professional-web",
//   storageBucket: "michael-professional-web.appspot.com",
//   // messagingSenderId: "338564911587",
//   appId: "1:468603050085:web:4d5e5d96b9f4ec0c24ce8c"
// });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
